import { apiv1 } from "@/request/apiv1";
import qs from "qs";

/** 
 * @param {Object} params 
 * @returns Promise
 */
export function GetClientsStatisticsInvitations(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/statistics/invitations?${qs.stringify(params,
            { arrayFormat: "repeat" }
        )}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function GetClientsStatisticsInvitationsSummary(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/statistics/invitations/summary?${qs.stringify(params,
            { arrayFormat: "repeat" }
        )}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}